import React from "react";
import ClassicHeader from "../../Components/Header/ClassicHeader";
import AboutDetails from "./partials/AboutDetails";
import { useQuery } from "react-query";
import { getApiData } from "../../Services/apiMethods";

const About = () => {
  const getAboutDetails = () => {
    return getApiData("aboutus");
  };
  const {
    isLoading: aboutIsLoading,
    data: aboutData,
    isError: isAboutError,
    error: aboutError,
  } = useQuery("about-us", getAboutDetails);

  const getCompanyContent = () => {
    return getApiData("mission-vision");
  };
  const { isLoading, data, isError, error } = useQuery(
    "mission-vission-values",
    getCompanyContent
  );

  if (aboutIsLoading) {
    return "Loading... PLease wait a moment.";
  }

  if (isAboutError) {
    return aboutError.message;
  }

  if (isLoading) {
    return "Loading... PLease wait a moment.";
  }

  if (isError) {
    return error.message;
  }

  const aboutUs = aboutData.data.aboutUs;
  const content = data.data.companyMission;

  return (
    <>
      <ClassicHeader
        bgImage={process.env.REACT_APP_IMAGE_BASE_URL + aboutUs.bgImage}
        title="About Us"
      />
      <AboutDetails data={aboutUs} content={content} />
    </>
  );
};

export default About;
